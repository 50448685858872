import React, { Component } from 'react';
import { GoInfo as Info } from 'react-icons/go'
import { withStyles } from '@material-ui/core/styles';

import Input from '../components/Input/index';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { MdClose as CloseIcon, MdGavel as Judge } from 'react-icons/md';
import Typography from '@material-ui/core/Typography';

import "../styles/Join.scss"

class Join extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            username: "",
            rounds: 3,
            timer: 60,
            gameId: "",
            infoModal: false,
        }

        this.join = this.join.bind(this);
        this.create = this.create.bind(this);
    }

    toggleModal = () => {
        this.setState({ infoModal: !this.state.infoModal })
    }

    join(){
        let request = {
          'type': 'request',
          'action': 'join',
          'name': this.state.username,
          'gameId': this.state.gameId
        };
        this.props.sendObject(request);
    }

    create(){
        if(!this.state.username || this.state.username === "") alert("Please enter a username!")
        let request = {
          'type': 'request',
          'action': 'create',
          'max_timer': this.state.timer,
          'max_rounds': this.state.rounds,
          'name': this.state.username
        };
        this.props.sendObject(request);
    }

    setField(key, value) {
        this.setState({[key]: value})
    }

    getInputs() {
        if(this.state.new) {
            return (
                <div id="form">
                    <Input
                        onChange={(event)=>this.setField("username", event.target.value)}
                        value={this.state.username}
                        type="text"
                        label="Player's name"
                    />
                    <div id="params">
                        <Input
                            onChange={(event)=>this.setField("rounds", event.target.value)}
                            value={this.state.rounds}
                            type="number"
                            label="Number of rounds"
                            min={1}
                            max={15}
                        />
                        <div className="spacer"/>
                        <Input
                            onChange={(event)=>this.setField("timer", event.target.value)}
                            value={this.state.timer}
                            type="number"
                            label="Timer (in seconds)"
                            min={30}
                            max={360}
                        />
                    </div>
                    <button onClick={this.create}>Create Game</button>
                </div>
            )
        } else  {
            return (
                <div id="form">
                    <Input
                        onChange={(event)=>this.setField("username", event.target.value)}
                        value={this.state.username}
                        type="text"
                        label="Player's name"
                    />
                    <Input
                        onChange={(event)=>this.setField("gameId", event.target.value.toUpperCase())}
                        value={this.state.gameId}
                        type="text"
                        label="Room code"
                        pattern={/^[a-zA-Z0-9]{6}$/}
                        message="Game code must be alphanumeric and 6 characters long."
                    />
                    <button onClick={this.join}>Join Game</button>
                </div>
            )
        }
    }

    renderModal() {
        const Header = withStyles({
            root: {
                    position: 'relative',
                    margin: 0,
                    padding: 25,
                },
            closeButton: {
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey',
            },
        })((props) => 
            <DialogTitle disableTypography >
                <Typography variant="h3" style={{textAlign: 'center', fontFamily: "'Roboto Condensed', sans-serif"}}>word•smyth</Typography>
                <IconButton
                    className={props.classes.closeButton} 
                    aria-label="close" 
                    onClick={this.toggleModal}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>)
        return (
            <Dialog onClose={this.toggleModal} aria-labelledby="info_title" open={this.state.infoModal}>
                <Header/>
                <DialogContent style={{padding: '16px 24px'}}>
                    Create a game room and invite your friends to play online with your room code. When creating a game, you can customize the number of rounds in one game, or change the length of the timer during each phase of a round.
                    <br/><br/>
                    During each round, one player will be designated as the judge (<Judge/>). The judge will choose from a selection of proverbs, all real, from around the world. Once the real quote has been chosen, the first half of it will be presented to the rest of the players, who must write their own version of the ending.
                    <br/><br/>
                    The real quote and the players' newly written versions will be presented in random order, and each player must vote for the version of the quote they belive is the original. Points are awarded for guessing correctly (2) as well as for tricking other players with their quote ending (1 per vote). If no player guesses correctly, the judge will be awarded 3 points. The judge can also award bonus points to their favorite player quotes.
                </DialogContent>
            </Dialog>
        )
    }
    
    render() {
        return (
            <div id="join">
                {this.renderModal()}
                <div id='title'>
                    word•smyth
                    <Info onClick={this.toggleModal}/>
                </div>
                <div className="nav">
                    <div className={`navitem ${this.state.new ? "selected" : ""}`} onClick={()=>this.setState({new: true})}>Start a new Game?</div>
                    <div className={`navitem ${!this.state.new  ? "selected" : ""}`} onClick={()=>this.setState({new: false})}>Or join a friend's?</div>
                </div>
                {this.getInputs()}
            </div>
        )
    }
} export default Join;